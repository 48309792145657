export default defineNuxtRouteMiddleware(async () => {
  const app = useNuxtApp();

  if (app.$user.session.hasOrganisation) {
    return;
  }

  try {
    await app.$user.fetchAndStoreCurrentOrganisation();
  } catch (error) {
    console.error(error);
  }
});
